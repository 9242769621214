import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - Jobbörsen",
  "description": "Posting - Jobbörsen",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Welche Daten müssen zur Schaltung vorliegen?`}</h2>
    <p>{`Abhängig von der Jobbörse können zusätzliche Informationen benötigt werden. Das Anforderungsprofil kann wie folgt festgelegt und an die Jobbörsen übermittelt werden. Sind Informationen bereits in der Ausschreibungskarte hinterlegt worden, müssen diese nicht noch einmal eingegeben werden.`}</p>
    <img src="/images/gettingstarted/jobb-liste.png" alt="Jobboersen" style={{
      "width": "100%"
    }} />
    <h2>{`Welche Jobbörsen sind für das Multiposting verfügbar?`}</h2>
    <p>{`Sie finden alle bei uns gelisteten Jobbörsen in der Jobbörsen-Übersicht. `}</p>
    <h2>{`Ist die Jobbörse der Bundesagentur für Arbeit buchbar?`}</h2>
    <p>{`Ja. Sie können die Jobbörse der Bundesagentur für Arbeit bei uns buchen – ebenso wie jede andere bei uns gelistete Jobbörse. `}</p>
    <p>{`Hinweis: Falls Sie über einen betreuten Account verfügt, können wir das ebenfalls abbilden. Es muss jedoch über die Arbeitgeberschnittstelle eine individuelle Schnittstelle von uns erstellt werden. Dazu benötigen wir die folgenden Informationen:`}</p>
    <ul>
      <li parentName="ul">{`Ihr Zertifikat`}</li>
      <li parentName="ul">{`Ihre PIN`}</li>
      <li parentName="ul">{`Ihre SupplierID`}</li>
      <li parentName="ul">{`Ihre Hiring-OrgID`}</li>
    </ul>
    <p>{`Bitte sprechen Sie uns einfach an unter `}<a parentName="p" {...{
        "href": "mailto:support@inriva.com"
      }}>{`support@inriva.com`}</a></p>
    <h2>{`Es fehlt eine für uns wichtige Jobbörse`}</h2>
    <p>{`Ihre Wunsch-Jobbörse ist nicht dabei? Kein Problem: Jede Jobbörse lässt sich integrieren. Senden Sie uns einfach eine E-Mail.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      